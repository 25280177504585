body {
  margin: 0;
  font-family: 'Veranda';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.classicPageWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index:1300;
  filter: blur(0rem);
}

.classicPopupBkg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1301;

  display: flex;
  justify-content: center;
  align-items: center;
}

.classicPopupInner {
  position: relative;
  width: 100%;
  max-width: 450;
  background-color: #FFF;
}

h1, h2, h3, h4, h5, h6{
  margin-top: 1rem;
  margin-bottom: 1rem;
}